<template>
  <div class="maquinaria">
    <h1>Maquinaria</h1>
    <div class="contenedor">
      <div
        class="contenedor-item"
        v-for="item in MaquinariaEsp"
        :key="item.id"
        ref="items"
      >
        <div class="temp" v-show="!visible[item.id]" />
        <transition name="escalar" appear :duration="700">
          <img :src="item.imgFondo" :alt="item.alt" v-show="visible[item.id]" />
        </transition>
        <Deslizar
          :name="'deslizarOcultar'"
          :ocultar="true"
          :posInicial="{ x: '0', y: '20px' }"
          :posFinal="{ x: '0', y: '0' }"
          :duracion="1300"
        >
          <p v-show="visible[item.id]">{{ item.nombre }}</p>
        </Deslizar>
      </div>
      <ul>
        <li>Torno Lathe 20" de volteo, 2m entre centros</li>
        <li>Fresadora CNC Vertical Reeves 16" x 80</li>
        <li>Torno Revolver Lathe 10" de volteo, 30" de bancada</li>
        <li>Soldadora MIC 90A</li>
        <li>Soldadora 500ACD</li>
      </ul>
    </div>
  </div>
</template>

<script>
import Deslizar from "@/components/Deslizar.vue";

export default {
  name: "Maquinaria",
  components: { Deslizar },
  data() {
    return {
      observer: {},
      visible: [],
      MaquinariaEsp: [
        {
          id: 0,
          nombre: 'Torno CNC Lathe 8" de volteo, 6" al aire',
          imgFondo: require("@/assets/torno 8in.png"),
          alt: "torno 8in"
        },
        {
          id: 1,
          nombre: 'Fresadora CNC Vertical Mc Lane 14 1/2" x 36',
          imgFondo: require("@/assets/torno 20in.png"),
          alt: "fresadora 14 1/2in x 36"
        },
        {
          id: 2,
          nombre: 'Torno 24" de volteo, 2m entre centros',
          imgFondo: require("@/assets/torno 24in.png"),
          alt: "torno 20in"
        }
      ]
    };
  },
  mounted() {
    this.observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.intersectionRatio >= 0.5) {
            this.observer.unobserve(entry.target);
            let indice = entry.target.getAttribute("idref");
            this.visible.splice(indice, 1, true);
            // this.$emit('visible', `${this.visible}`);
          }
        });
      },
      { threshold: 0.5 }
    );

    this.$refs.items.forEach((el, index) => {
      this.observer.observe(el);
      el.setAttribute("idref", index);
      this.visible[index] = false;
    });
  },
  beforeDestroy() {
    this.observer.disconnect();
  }
};
</script>

<style lang="stylus" scoped>
.maquinaria
  display flex
  flex-flow column wrap
  justify-content center
  align-items center
  text-align center

  img
    max-width 300px
    @media screen and (min-width: sm)
      max-width auto

.temp
  width 300px
  height 350px
  margin 1rem
</style>
